import React from "react"
import AuthPage from "../components/AuthPage"
import { BasicLayout } from "../layouts/index.js"
import LoginForm from "../components/LoginForm"

const LoginPage = () => {
  return (
    <BasicLayout>
      <AuthPage content={LoginForm} />
    </BasicLayout>
  )
}

export default LoginPage
