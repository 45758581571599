import React from "react"
import { useForm, Controller } from "react-hook-form"
import {
  Grid,
  TextField,
  Typography,
  Snackbar,
  CircularProgress,
  Link,
  IconButton,
} from "@material-ui/core"
import { Button } from "instadrops-ui"
import { navigate } from "gatsby"
import { validateEmail, callAnalyticsEvent } from "../utils"
import "./styles.css"
import useAuth from "../context/auth"
import { LOGIN_ERROR_MESSAGES } from "../utils/consts"
import Alert from "@material-ui/lab/Alert"
import EmailSentMessage from "./EmailSentMessage"
import { ArrowBack } from "@material-ui/icons"
import GoogleAuthButton from "./GoogleAuthButton"

const LoginForm = () => {
  const { isAuthenticated, login, checkAuthentication } = useAuth()

  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  })

  const [loading, setLoading] = React.useState(false)
  const [openSnackbar, setOpenSnackbar] = React.useState(false)
  const formState = watch()
  const [errorCode, setErrorCode] = React.useState(0)
  const [emailSent, setEmailSent] = React.useState(0)

  const isButtonDisabled = !formState.email.length || !formState.password.length

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate("/")
    }
  })

  React.useEffect(() => {
    const googleToken = new URLSearchParams(window.location.search).get(
      "google-token"
    )
    if (googleToken) {
      localStorage.setItem("token", googleToken)
      checkAuthentication(true)
    }
  }, [])

  const onSubmit = async (data) => {
    setLoading(true)
    const { email, password } = formState

    try {
      await login(email.trim(), password)
      callAnalyticsEvent("login.success")
      navigate("/accountAdmin")
    } catch (err) {
      const errorCode = err?.response?.data?.code
      setLoading(false)
      callAnalyticsEvent("login.error")
      if (errorCode === 1104) {
        setEmailSent(true)
      } else {
        setOpenSnackbar(true)
        setErrorCode(errorCode)
      }
    }
  }

  return (
    <Grid
      container
      justify="center"
      alignContent="center"
      style={{ height: "90vh" }}
    >
      <Grid item xs={12} md={7} container>
        <IconButton onClick={() => navigate("/")}>
          <ArrowBack />
        </IconButton>
        <Typography style={{ userSelect: "none", marginTop: 14 }}>
          Volver al inicio
        </Typography>
      </Grid>

      <Grid item xs={8} container justify="center">
        <Typography variant="h4" style={{ fontWeight: 800 }}>
          Alchtec Suite
        </Typography>
      </Grid>

      <Grid item xs={8} container justify="center">
        <Typography>Ingreso usuarios</Typography>
      </Grid>

      {loading ? (
        <Grid
          style={{ height: "30vh" }}
          justify="center"
          alignContent="center"
          container
        >
          <CircularProgress />
        </Grid>
      ) : emailSent ? (
        <EmailSentMessage message="Para continuar porfavor verifica tu correo electronico" />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container justify="center">
            <Grid item xs={11} md={8}>
              <Grid container spacing={4} style={{ padding: 48 }}>
                <Grid item xs={12}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      validate: (value) =>
                        validateEmail(value) ||
                        "Ingresa un correo electrónico válido",
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Email"
                        id="outlined-size-small"
                        variant="outlined"
                        size="small"
                        error={!!errors.email}
                        helperText={errors.email?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Contraseña"
                        id="outlined-size-small"
                        variant="outlined"
                        size="small"
                        type="password"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} style={{ padding: 12 }}>
                  <Typography>
                    {` ¿Olvidaste `}
                    <Link
                      style={{ cursor: "pointer", fontWeight: 800 }}
                      onClick={() => navigate("/recover-password")}
                    >
                      tu contraseña?
                    </Link>
                  </Typography>
                </Grid>
                <Grid item xs={12} container justify="center">
                  <Button disabled={isButtonDisabled} isSubmit size="small">
                    Ingresar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackbar}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="error">
          {LOGIN_ERROR_MESSAGES[errorCode]}
        </Alert>
      </Snackbar>
    </Grid>
  )
}

export default LoginForm
