import React, { useState } from "react"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import { handleGoogleSignIn } from "../utils/handleGoogleSignIn"
import GoogleButton from "react-google-button"

const useStyles = makeStyles(() => ({
  googleAuthButton: {},
}))

export default function GoogleAuthButton() {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  return (
    <GoogleButton
      onClick={() => handleGoogleSignIn(setLoading)}
      label="Acceder con Google"
    />
  )
}
